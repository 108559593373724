import React, {useEffect, useState} from "react";
import {Link} from "@clout-team/icons/react";
import spaceIcon from '../../assets/img/space.svg';
import leftImg from '../../assets/img/left.svg';
import rightImg from '../../assets/img/right.svg';
import {Button, Dialog, Header, Input, Layout, Text, TooltipWrap} from "@clout-team/web-components";
import {getUrlParamsByKey, isLink} from "@clout-team/helpers";
import {START_ME, START_ME_PARAM, PROJECT_PARAM} from "../../constants";
import UserDialog from "../../components/UserDialog/UserDialog";
import PageTop from "../../components/PageTop/PageTop";
import {generateLink} from "../../helpers";
import {IUser} from "../../interface";
import './Home.scss';
import {viewWork} from "../../api";

const Home = (props: {
  openRegisterDialog?: boolean
}) => {
  const {openRegisterDialog = false} = props;
  const [dialog, setDialog] = useState<boolean>(false)
  const [registerDialog, setRegisterDialog] = useState<boolean>(openRegisterDialog)
  const [successDialog, setSuccessDialog] = useState<boolean>(false)
  const [disable, setDisable] = useState<boolean>(true);
  const [error, setError] = useState<string>('');
  const [copyText, setCopyText] = useState<string>('Скопировать ссылку');
  const [redirect, setRedirect] = useState<string>('');
  const [projectTitle, setProjectTitle] = useState<string>('Заполните данные');
  const [user, setUser] = useState<IUser>({
    name: '',
    surname: ''
  });

  useEffect(() => {
    const hashCurrent = getUrlParamsByKey(document.location.href, START_ME_PARAM);
    const project = getUrlParamsByKey(document.location.href, PROJECT_PARAM);
    if (hashCurrent && project && hashCurrent === START_ME) {
      viewWork(project).then((r: any) => {
        if (r && r.response) {
          setProjectTitle(r.response.title)
        }
      });
      setRegisterDialog(true);
    }
  }, [])

  const addError = () => {
    setDisable(true)
    setError('')
  }

  const onChange = (e: any) => {
    if ((e.target.value).length > 0) {
      if (isLink(e.target.value)) {
        const url = new URL(e.target.value);
        // eslint-disable-next-line no-restricted-globals
        history.pushState('', '', url?.search);
        setDisable(false)
        setError('')
      } else addError();
    } else {
      addError();
    }
  }

  const nextHandle = () => {
    const link = document.location.href;
    const hash = getUrlParamsByKey(link, START_ME_PARAM);
    const project = getUrlParamsByKey(link, PROJECT_PARAM);
    if (hash && project && hash === START_ME) {
      setRegisterDialog(true)
      viewWork(project).then((r: any) => {
        if (r && r.response) {
          setProjectTitle(r.response.title)
        }
      });
      setDialog(false)
    } else {
      setError('Ссылка не распознана')
    }
  }

  const goToTest = () => {
    setRedirect(generateLink())
  }

  return (
    <div className={'home-page'}>
      {
        successDialog && <Dialog
          closeOnClickOutside={false}
          className={'success-dialog'}
          dialogTitle={{
            title: `${user.name} ${user.surname}`
          }}
          dialogButtons={{
            left: [
              <div onMouseEnter={() => setCopyText('Копировать ссылку')}>
                <TooltipWrap className={'copied'} hint={copyText}>
                  <Button color={'white'} key={'btn-5'} size={'lg'} onClick={() => {
                    setCopyText('Ссылка скопирована в буфер обмена')
                    navigator.clipboard.writeText(generateLink())
                  }}>
                    Копировать
                  </Button>
                </TooltipWrap>
              </div>
            ],
            right: [
              <a target={'_blank'} href={redirect} rel="noreferrer">
                <Button key={'btn-6'} size={'lg'} onClick={goToTest}>
                  Перейти к заданию
                </Button>
              </a>
            ]
          }}
          onClose={() => setSuccessDialog(false)}
        >
          <div>
            <div className={'text-input'}>
              <Header size={'sm'}
                      left={<Text type={'secondary'} size={'footnote'}>Сохраните ссылку на задание, ссылка активна 72
                        часа</Text>} noPadding={true}/>
              <Input capitalizeValue={false} iconLeft={<Link/>} disabled={true} placeholder={generateLink()}/>
            </div>
          </div>
        </Dialog>
      }
      {
        registerDialog &&
        <UserDialog title={projectTitle} setUser={setUser} setSuccessDialog={setSuccessDialog} onClose={() => setRegisterDialog(false)}/>
      }
      {
        dialog && <Dialog
          closeOnClickOutside={false}
          className={'link-dialog'}
          dialogTitle={{
            title: 'Введите ссылку на задание'
          }}
          dialogButtons={{
            right: [
              <Button disabled={disable} key={'btn-6'} size={'lg'} onClick={nextHandle}>
                Далее
              </Button>
            ]
          }}
          onClose={() => setDialog(false)}
        >
          <div className={'link-dialog-content'}>
            <Input capitalizeValue={false} focus={true} onChange={onChange} errorMessage={error} onDismissClick={() => addError()}
                   placeholder={'Ссылка'}/>
          </div>
        </Dialog>
      }
      <PageTop/>
      <div className={'home-page-content'}>
        <div className="home-page-welcome">
          <Layout gap={'lg'} vertical={true} isAlignCenter={true}>
            <div className={'home-page-welcome--logo'}>
              <img src={spaceIcon} alt={'task-logo'}/>
            </div>
            <div className={'home-page-welcome--text'}>
              Добро пожаловать в сервис <br/>Clout.test
            </div>
            <div className={'home-page-welcome--description'}>
              <Text type={'secondary'} size={'footnote'}>Благодаря Clout test вы можете проводить тесты и
                собирать<br/> полученные данные в Clout Spaces</Text>
            </div>
          </Layout>
          <div className={'home-page--button'}>
            <Button size={'lg'} onClick={() => setDialog(true)}>Пройти тестирование</Button>
          </div>
        </div>
        <div className={'img-left'}>
          <img src={leftImg} alt={'img-left'}/>
        </div>
        <div className={'img-right'}>
          <img src={rightImg} alt={'img-right'}/>
        </div>
      </div>
    </div>
  )
}

export default Home;
