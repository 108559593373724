import React, {useCallback, useEffect, useMemo, useState} from "react";
import {IStatus, IUser} from "../../interface";
import classNames from "classnames";
import {
  convertMessage,
  getFinishTime,
  sortMessages
} from "../../helpers";
import {useParams} from "react-router-dom";
import {
  cloutTestFile,
  cloutAsistentFile,
  media,
  mediaPrev
} from "../../mock";
import {addComment, viewWork} from "../../api";
import Preload from "../../components/Preload/Preload";
import {Clock, EmojiNone, LogoClout} from "@clout-team/icons/react";
import {
  Avatar, Button,
  ChatBody,
  Dialog,
  Divider,
  Header,
  Layout,
  MediaPreview,
  StatusItem,
  Tag,
  Text,
  Window
} from "@clout-team/web-components";
import Timer from "../../components/Timer/Timer";
import Link from "../../components/Link/Link";
import './Main.scss';
import NotFound from "../NotFound/NotFound";

let timeOut: ReturnType<typeof setTimeout>;

const Main = () => {
  // @ts-expect-error
  let {hash} = useParams();

  const [messages, setMessages] = useState<any>([])
  const [isPreload, setIsPreload] = useState<boolean>(true)
  const [hideTime, setHideTime] = useState<boolean>(true)
  const [showBar, setShowBar] = useState<boolean>(false)
  const [showInput, setShowInput] = useState<boolean>(false)
  const [disableArrow, setDisableArrow] = useState<boolean>(true)
  const [notFound, setNotFound] = useState<boolean>(false)
  const [user, setUser] = useState<IUser>({
    name: '',
    surname: ''
  })
  const [status, setStatus] = useState<IStatus>({
    id: 1,
    title: 'Ожидание выполнения',
    timeTitle: "Время выполнения задания:",
    statusColor: 'blue-5-light'
  });

  const [wind, showWind] = useState(false);

  const windowView = useMemo(() => {
    return <Window
      items={[media]}
      onClose={() => {
        showWind(false)
      }}
      isModal={true}
      disabled={!wind}/>;
  }, [wind]);

  const classes = classNames(
    'clout-test-dialog',
    {
      'disable-arrow': disableArrow
    }
  )

  useEffect(() => {
    if (showInput) {
      setTimeout(() => {
        const input = document.querySelector('.DraftEditor-root');
        if (input) {
          const checkWords = () => {
            const span: any = input.querySelector('span[data-text]');
            if (span && span.innerText.length > 0) setDisableArrow(false)
            else setDisableArrow(true)
          }
          input.addEventListener('keyup', (e: any) => {
            checkWords();
          })
          checkWords();
        }
      }, 500)
    }
  }, [showInput, messages])

  const changeStatus = (newStatus: IStatus) => {
    if (newStatus.id === 2) {
      setHideTime(false)
      setShowBar(true) //FIXME
      setShowInput(true)
    } else if (newStatus.id === 3) {
      setHideTime(false)
      setShowBar(false)
      setShowInput(true)
      localStorage.setItem('time', getFinishTime(newStatus.start_at || 0, newStatus.finish_at || 0))
    }
    setStatus((state: IStatus) => {
      return newStatus
    })
  }

  const MessageButtons = (props: {
    message: any
    isDisables: boolean
  }) => {
    const {message, isDisables} = props;
    const [disabled, setDisabled] = useState<boolean>(isDisables);

    const handleClickBtn = (v: any, id: number) => {
      setDisabled(true);
      if (hash && v.command) {
        if (v.command.toLowerCase() === 'начать') {
            // в процессе
            changeStatus({
              id: 2,
              title: 'Выполнение задания',
              timeTitle: 'Время выполнения задания:',
              statusColor: 'yellow-1',
              start_at: Math.floor(Date.now() / 1000),
            });
        }
        addMessage(addButtons({
          "id": id + 1,
          "message": v.command,
          "created_at": Math.floor(Date.now() / 1000),
          "user_name": user.name,
          "user_surname": user.surname,
          "user_avatar": "",
          "files": [],
          "is_executor": true
        }));
        addComment(hash, v.command);
      }
    }

    return (
      <div style={{marginTop: 8}}>
        <Layout gap={'md'}>
          {message.action.map((v: any) => {
            return <Button disabled={disabled} color={v.color} onClick={() => handleClickBtn(v, message.id)}>{v.title}</Button>
          })}
        </Layout>
      </div>
    )
  }

  const addButtons = useCallback((message: any, msgArr = [], title = '') => {
    const msg = convertMessage(message);
    // TODO пока уберем кнопки Далее
    if (
      message.action &&
      message.action.length > 0 &&
      (message.action[0].title).toLowerCase() !== 'далее' &&
      (message.action[0].title).toLowerCase() !== '/push'
    ) {
      let disabled = true;
      if (msgArr.length > 0 && msg.id === msgArr[msgArr.length - 1].id)
        disabled = false;
      msg.messageCardItem = <MessageButtons message={message} isDisables={disabled}/>
    }

    // add git video
    if ((msg.message).indexOf('[video]') > -1) {
      let video = (msg.message).match(/\[video\](.*?)\[\/video\]/gi);
      msg.message = (msg.message).replace(video[0], '');
      // video = video[0].replace('[video]', '');
      // video = video.replace('[/video]', '');
      // video - this video link
      // msg.custom = <>
      msg.messageCardItem = <div style={{marginTop: '-40px'}}>
        <Divider size={`16`} direction={`vertical`}/>
        <div style={{position: "relative"}}><MediaPreview
          style={{
            height: 200
          }}
          canPreview={true}
          showBadge={true}
          data={mediaPrev}
          onClick={() => {
            showWind(true)
          }}
        />
          <div className="component-media-status-item has-hover" style={{
            position: "absolute",
            top: "80px",
            left: "275px",
            zIndex: "9"
          }}>
            <svg width="20" height="20" viewBox="0 0 16 16" style={{fill: "currentcolor"}}>
              <path d="M13.778 6.948a1.2 1.2 0 010 2.103l-8 4.397A1.2 1.2 0 014 12.397V3.602A1.2 1.2 0 015.778 2.55l8 4.398z"/>
            </svg>
          </div>
        </div>
        {msg.messageCardItem}
      </div>
    }

    // add clout test zip
    if ((msg.message).indexOf('[file]') > -1) {
      let file = (msg.message).match(/\[file\](.*?)\[\/file\]/gi);
      msg.message = (msg.message).replace(file[0], '');
      file = file[0].replace('[file]', '');
      file = file.replace('[/file]', '');
      // file - this file link

      if (title === 'Бизнес Ассистент') {
        msg.files = [cloutAsistentFile]
      } else {
        msg.files = [cloutTestFile]
      }
    }

    return msg;
  }, [MessageButtons]);

  const scrollDown = () => {
    setTimeout(() => {
      const scrollMessages = document.querySelector('.message-wrapper-component');
      if (scrollMessages) scrollMessages.scrollTo({top: scrollMessages.scrollHeight, behavior: 'smooth'});
    }, 200)
  }

  const addMessage = (message: any) => {
    setMessages((state: any) => {
      return sortMessages([message, ...state]);
    })
    scrollDown();
  }

  const addMessages = useCallback((messageArr: any, all: boolean = false) => {
    if (all) {
      // добавляем все с нуля
      const allMessages = sortMessages(messageArr);
      setMessages((state: any) => {
        if (allMessages.length !== state.length) scrollDown();
        return allMessages;
      });
    } else {
      // добавляем к уже существующим
      setMessages((state: any) => {
        return sortMessages([...messageArr, ...state]);
      })
      scrollDown();
    }

  }, [messages.length]);

  const getWorkInfo = async () => {
    const info: any = await viewWork(hash);
    return info.response;
  }

  // мониторим статус выполнения и новые сообщения
  const monitoringStatus = () => {
    timeOut = setTimeout(async function () {
      const info = await getWorkInfo();
      if (info) {
        if (info.finish_at && status.id !== 3) {
          changeStatus({
            id: 3,
            title: 'На проверке',
            timeTitle: 'Задание выполнено за:',
            statusColor: 'green-1',
            start_at: info.start_at,
            finish_at: info.finish_at,
          });
        }

        let backMessages = (info.messages)
        backMessages = backMessages.map((m: any) => addButtons(m, backMessages, info.title))
        addMessages([...backMessages], true)

        monitoringStatus();
      }
    }, 5000);
  };

  useEffect(() => {
    localStorage.setItem('hash', hash);
    viewWork(hash).then((workInformation: any) => {
      if (workInformation.response) {
        if (workInformation.response.messages && (workInformation.response.messages).length > 0) {
          let backMessages = (workInformation.response.messages);
          backMessages = backMessages.map((m: any) => addButtons(m, backMessages, workInformation.response.title));
          const user = workInformation.response.user;
          if (user) {
            setUser({
              name: user.name,
              surname: user.surname
            });
          }

          if (workInformation.response.finish_at) {
            // если закончено
            changeStatus({
              id: 3,
              title: 'На проверке',
              timeTitle: 'Задание выполнено за:',
              statusColor: 'green-1',
              start_at: workInformation.response.start_at,
              finish_at: workInformation.response.finish_at,
            });
          } else if (workInformation.response.start_at) {
            // в процессе
            changeStatus({
              id: 2,
              title: 'Выполнение задания',
              timeTitle: 'Время выполнения задания:',
              statusColor: 'yellow-1',
              start_at: workInformation.response.start_at,
            });
          } else {
            setStatus((state: IStatus) => {
              return {...state, start_at: workInformation.response.start_at}
            })
          }
          addMessages([...backMessages], true);
          monitoringStatus();
        } else {
          setNotFound(true);
        }
      } else {
        setNotFound(true);
      }
      setIsPreload(false)
    });


    return () => clearTimeout(timeOut)
  }, [])

  const onSubmit = (value: string, second: string, _files: any) => {
    if (hash && (value.length > 0 || _files.length > 0)) {
      addMessage(addButtons({
        "id": messages[messages.length - 1].id + 1,
        "message": value,
        "created_at": Math.floor(Date.now() / 1000),
        "user_name": user.name,
        "user_surname": user.surname,
        "user_avatar": "",
        "files": _files.length > 0 ? _files : [],
        "is_executor": true
      }));
      clearTimeout(timeOut);
      addComment(hash, value, _files).then(() => monitoringStatus());
    }
  }

  return (
    <>
      {isPreload ? <Preload/> :
        notFound ? <NotFound/> : (
          <>
            <div className={'page-top'}>
              <div className={'test-logo'}>
                <LogoClout/>
                Clout.test
              </div>
            </div>

            <div className={'test-content'}>
              <Dialog
                isShadow={true}
                size={'lg'}
                isModal={false}
                className={classes}
              >
                <>
                  <div className={'component-page-header'}>
                    <Header
                      borderBottom={true}
                      className={'component-page-header-type-test'}
                      size={'lg'}
                      left={
                        <Layout gap={'sm'}>
                          <Layout gap={'sm'} horizontal isAlignCenter>
                            <StatusItem
                              isTooltip={false}
                              preventDefault={false}
                              size={24}
                              color={status.statusColor}/>
                            <Text heading size={'header-bold'}>{status.title}</Text>
                          </Layout>
                        </Layout>
                      }
                      center={hideTime ? '' : (
                        <Layout className={'text-center'} vertical gap={'none'}>
                          <Text size={'metadata'} defaultPadding={false}>
                            {status.timeTitle}
                          </Text>
                          <Text size={'metadata'} defaultPadding={false}>
                            {status.start_at && status.finish_at ?
                              getFinishTime(status.start_at, status.finish_at) :
                              // @ts-expect-error
                              <Timer start={showBar} startTime={status.start_at * 1000 || 0}/>}
                          </Text>
                        </Layout>
                      )}
                      right={hideTime ? user.name ? (
                        <Tag size={'lg'} icon={<Avatar size={'16'} text={user.name.substring(0, 1).toUpperCase()}/>}>{`${user.name} ${user.surname}`}</Tag>
                      ) : '' : (
                        <Layout gap={'sm'}>
                          <Layout horizontal gap={'xsm'}>
                            <Avatar size={'32'} color={'transparent'}
                                    icon={status.id === 3 ? <EmojiNone/> : <Clock/>}/>
                          </Layout>
                          {user.name && (
                            <Tag size={'lg'} icon={<Avatar size={'16'}
                                                           text={user.name.substring(0, 1).toUpperCase()}/>}>{`${user.name} ${user.surname}`}</Tag>
                          )}
                        </Layout>
                      )}
                    />
                  </div>

                  <ChatBody
                    isActionControl={false}
                    isShowInput={showInput}
                    messageType={'bubble'}
                    messageBar={{
                      children: <Link/>,
                      hideClose: true,
                      type: 'warning'
                    }}
                    isShowMessageBar={false}
                    messages={messages.filter((m: any) => m.message.indexOf('Напишите "Начать"') === -1)}
                    onSubmit={onSubmit}
                    placeholder={'Появился вопрос или комменатрий, пишите. Мы ответим в ближайшее время'}
                  />
                </>
              </Dialog>
            </div>
          </>
        )}
      {wind && windowView}
    </>
  );
}

export default Main;
