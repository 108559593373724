export const API_URL = 'https://api.lenzaos.com';
export const API_FILES = 'https://files.lenzaos.com/upload';

export const URLS = {
    CREATE_WORK_USER: `${API_URL}/workTest.registration`,
    VIEW_WORK: `${API_URL}/workTest.view`,
    ADD_FILES: `${API_URL}/workTest.workAddFile`,
    UPLOAD_FILE: `${API_URL}/workTest.uploadFile`,
    ADD_COMMENT: `${API_URL}/workTest.workAddMessage`,
    WORK_TEST_CREATE: `${API_URL}/workTest.create`,
    PROFILE_GET: `${API_URL}/profile.get`
}
