import React from 'react';
import ReactDOM from 'react-dom';
import '@clout-team/web-components/build/index.css';
import '@clout-team/web-components/build/';
import './App.scss';
import App from './App';
import { BrowserRouter } from 'react-router-dom';

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter basename={'/'}>
      <App/>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);
