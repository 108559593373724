import React, {useState} from "react"
import {Text, TooltipWrap} from "@clout-team/web-components"
import './Link.scss'
import {generateLink} from "../../helpers";

const Link = () => {
  const [text, setText] = useState<string>('Копировать ')

  const copyLink = () => {
    navigator.clipboard.writeText(generateLink())
    setText('Скопировано')
  }

  return (
    <Text>Не закрывайте страницу и сохраните <TooltipWrap hint={text} arrow={'top'}><span onMouseEnter={() => setText('Копировать ')} onClick={copyLink} className={'copy-link'}>ССЫЛКУ</span></TooltipWrap>. На данной странице Вы сможете оставить комментарии после того как сделаете <b>push</b> в свою ветку <b>в удаленный репозиторий</b></Text>
  )
}

export default Link;
