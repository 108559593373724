import {API_FILES} from "../constants/urls";
import spaceIcon from "../assets/img/space.svg";
import {TOKEN} from "../constants";
import { Cookies } from "react-cookie";
import mainJson from '../../package.json';
const cookies = new Cookies();

export const generateRandomString = () => (Math.random() + 1).toString(36).substring(5);

export const fileUpload = function (file: any) {
  return new Promise(function (resolve, reject) {
    const token = cookies.get('token') || TOKEN;
    let object = '';
    const str = new FormData();

    str.append('token', token);
    str.append('file', file);
    const xhr = new XMLHttpRequest();
    xhr.open("POST", API_FILES, true);
    xhr.setRequestHeader('version', mainJson.apiVersion);
    xhr.upload.onprogress = function (e) {
      if (e.lengthComputable) {
        const percent: any = Math.floor((e.loaded / e.total) * 100);
        const stroke = percent * 1.56;
        if (parseInt(percent) > 0) {
          // код процесса загрузки
        }
      }
    }

    xhr.onreadystatechange = function (e: any) {
      if (e.target.readyState == 4) {
        if (e.target.status == 200) {
          try {
            object = JSON.parse(e.target.response);
            resolve(object);
          } catch (e) {
            console.log('e', e);
          }
        }
      }
    };
    xhr.send(str);
  });
}

export const convertMessage = (message: any) => {
  const newMessage: any = {
    "id": parseInt(message.id),
    "title": "",
    "message": message.message,
    "created_at": message.created_at,
    "created_at_ms": Date.now(),
    "updated_at": message.created_at,
    "updated_at_ms": Date.now(),
    "user_id": message.is_executor ? Math.floor(Math.random() * 999) : "1",
    "user_name": message.user_name === 'Task' ? 'test' : message.user_name,
    "user_surname": message.user_name === 'Task' ? 'Clout' : message.user_surname,
    "user_avatar": message.user_name === 'Task' ? spaceIcon : message.user_avatar,
    "my": message.is_executor,
    "system_json": "",
    "files": message.files,
    "is_received": true,
    "is_read": true,
    "is_checkbox": false,
    "checkbox_checked": false,
    "reply": null,
    "is_reply": false,
    "forward": null,
    "is_forward": false,
    "is_system": false,
    "is_delete": false,
    "event_id": "",
    "event": null,
    "is_event": false,
    "tmp_key": "6559447",
    "thread_id": "",
    "thread": null,
    "is_thread": false,
    "task_id": "",
    "topic_id": null,
    "task": null,
    "status_id": 2,
    "is_changed": false,
    "is_audio": false,
    "is_voice": false,
    "voice_listened_at": null,
    "is_cancel": false,
    "is_new": false,
    "work": null,
    "is_work": false,
    "only_me": false,
    "reminder": null,
    "is_reminder": false,
  }

  return newMessage;
}
export const isOnLink = () => {
  const path = document.location.pathname.split('/');
  if (path[2] && path[3]) return {token: path[3], hash: path[2]}
  return null;
}

export const getDifferentTimestamp = (start: number, finish: number) => {
  // @ts-ignore
  const diff = new Date(finish) - new Date(start),
  hours = parseInt(String((diff / (1000 * 60 * 60)))),
  hourInMls = hours * (1000 * 60 * 60),
  mins = parseInt(String(((diff - hourInMls) / (1000 * 60)))),
  minInMls = mins * (1000 * 60),
  seconds = parseInt(String(((diff - hourInMls - minInMls) / 1000)))

  return {h: hours, m: mins, s: seconds}
}

export const getFinishTime = (start: number, finish: number) => {
  const data = getDifferentTimestamp(start * 1000, finish * 1000);
  return `${data.h} час ${data.m} мин ${data.s} сек`;
}

export const generateLink = () => {
  return `${document.location.origin}/${localStorage.getItem('hash')}`;
}

export const sortMessages = (arr: any[]) => arr.sort(function(a, b){
  return parseInt(a.id) - parseInt(b.id)
})

export const isValidEmail = (
  inputString: string,
  validateLang?: boolean
): boolean => {
  const pattern =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([A-Za-zА-Яа-яЁё\-0-9]+\.)+[A-Za-zА-Яа-яЁё]{2,}))$/;

  const regex = new RegExp(pattern, "i");
  let valid = regex.test(inputString);

  if (validateLang) {
    const enPattern =
      /^(([^<>()[\]\\А-Яа-яЁё.,;:\s@"]+(\.[^<>()[\]\\А-Яа-яЁё.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([A-Za-z\-0-9]+\.)+[A-Za-z]{2,}))$/;
    const ruPattern =
      /^(([^<>()[\]\\A-Za-z.,;:\s@"]+(\.[^<>()[\]\\A-Za-z.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([А-Яа-яЁё\-0-9]+\.)+[А-Яа-яЁё]{2,}))$/;

    const regexEn = new RegExp(enPattern, "i");
    const regexRu = new RegExp(ruPattern, "i");

    valid = regexEn.test(inputString) || regexRu.test(inputString);
  }

  return valid;
};
