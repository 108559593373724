import React from "react";
import './Preload.scss';

const Preload = () => {

  return (
    <div className="splash-wrap">
      <div className="splash">
        <svg xmlns="http://www.w3.org/2000/svg" width="333" height="250" viewBox="0 0 333 250" fill="none">
          <g clipPath="url(#clip0_7505_33127)" filter="url(#filter0_dd_7505_33127)">
            <path
              d="M99.0329 44.7422C100.198 44.7071 101.276 44.1062 101.918 43.1348C105.098 38.3247 108.802 33.7679 113.027 29.5479C146.925 -4.3135 201.885 -4.3135 235.784 29.5479C238.804 32.5643 241.559 35.7542 244.048 39.0876C244.56 39.7741 245.303 40.2534 246.139 40.4441C263.953 44.509 279.584 56.0208 288.566 72.4395C290.065 75.1782 287.116 77.9944 284.195 76.8859C274.453 73.1886 263.885 71.1644 252.844 71.1644C242.949 71.1644 233.434 72.7902 224.553 75.7889C224.149 75.9253 223.716 76.0152 223.31 75.8877C222.41 75.6055 221.698 74.8108 221.605 73.7684C221.459 72.13 218.125 63.4572 211.695 56.6067C207.062 51.6699 202.429 48.5119 200.202 47.1328C180.723 35.6482 155.22 38.2637 138.486 54.9792C136.156 57.3062 134.106 59.7943 132.331 62.4052C130.832 64.7398 129.469 67.057 128.543 69.0234C125.556 75.3609 125.446 80.9477 125.411 82.7858C125.405 83.0591 125.401 83.2493 125.39 83.347C125.202 84.981 123.499 85.9635 121.987 85.3105C121.738 85.2026 121.459 85.0138 121.099 84.7711C119.613 83.7683 116.772 81.8514 109.163 80.9863C108.388 80.9703 107.584 80.9294 106.752 80.8617C78.4649 78.5549 51.6778 96.6963 44.0706 125.056C35.5037 156.993 54.4772 189.82 86.4493 198.377C94.1348 200.435 101.828 200.901 109.212 199.998C111.719 199.692 114.195 199.228 116.625 198.612C118.815 198.058 119.692 195.501 118.439 193.624L112.836 185.228C111.275 182.889 113.051 179.77 115.858 179.922L170.745 182.88C173.02 183.002 174.538 185.277 173.779 187.427L155.477 239.293C154.541 241.945 150.982 242.391 149.421 240.052L143.002 230.434C141.874 228.742 139.531 228.429 137.726 229.368C137.093 229.696 136.435 229.991 135.752 230.248C128.662 232.918 121.223 234.765 113.582 235.698C101.694 237.15 89.3503 236.388 77.1305 233.117C25.9513 219.419 -4.42064 166.871 9.2928 115.747C20.52 73.8928 57.7971 45.9864 99.0329 44.7422Z"
              fill="white"/>
            <path
              d="M99.0329 44.7422C100.198 44.7071 101.276 44.1062 101.918 43.1348C105.098 38.3247 108.802 33.7679 113.027 29.5479C146.925 -4.3135 201.885 -4.3135 235.784 29.5479C238.804 32.5643 241.559 35.7542 244.048 39.0876C244.56 39.7741 245.303 40.2534 246.139 40.4441C263.953 44.509 279.584 56.0208 288.566 72.4395C290.065 75.1782 287.116 77.9944 284.195 76.8859C274.453 73.1886 263.885 71.1644 252.844 71.1644C242.949 71.1644 233.434 72.7902 224.553 75.7889C224.149 75.9253 223.716 76.0152 223.31 75.8877C222.41 75.6055 221.698 74.8108 221.605 73.7684C221.459 72.13 218.125 63.4572 211.695 56.6067C207.062 51.6699 202.429 48.5119 200.202 47.1328C180.723 35.6482 155.22 38.2637 138.486 54.9792C136.156 57.3062 134.106 59.7943 132.331 62.4052C130.832 64.7398 129.469 67.057 128.543 69.0234C125.556 75.3609 125.446 80.9477 125.411 82.7858C125.405 83.0591 125.401 83.2493 125.39 83.347C125.202 84.981 123.499 85.9635 121.987 85.3105C121.738 85.2026 121.459 85.0138 121.099 84.7711C119.613 83.7683 116.772 81.8514 109.163 80.9863C108.388 80.9703 107.584 80.9294 106.752 80.8617C78.4649 78.5549 51.6778 96.6963 44.0706 125.056C35.5037 156.993 54.4772 189.82 86.4493 198.377C94.1348 200.435 101.828 200.901 109.212 199.998C111.719 199.692 114.195 199.228 116.625 198.612C118.815 198.058 119.692 195.501 118.439 193.624L112.836 185.228C111.275 182.889 113.051 179.77 115.858 179.922L170.745 182.88C173.02 183.002 174.538 185.277 173.779 187.427L155.477 239.293C154.541 241.945 150.982 242.391 149.421 240.052L143.002 230.434C141.874 228.742 139.531 228.429 137.726 229.368C137.093 229.696 136.435 229.991 135.752 230.248C128.662 232.918 121.223 234.765 113.582 235.698C101.694 237.15 89.3503 236.388 77.1305 233.117C25.9513 219.419 -4.42064 166.871 9.2928 115.747C20.52 73.8928 57.7971 45.9864 99.0329 44.7422Z"
              fill="url(#paint0_linear_7505_33127)"/>
            <path
              d="M192 118.256L197.834 111.445C224.258 80.5975 270.677 77.0685 301.514 103.564C332.351 130.059 335.927 176.545 309.502 207.393C285.841 235.016 246.182 240.72 215.995 222.617L203.249 214.973C201.64 214.009 201.115 211.922 202.077 210.311L217.314 184.803C218.276 183.193 220.36 182.669 221.969 183.634L234.715 191.278C249.906 200.387 269.894 197.482 281.797 183.588C295.109 168.047 293.307 144.628 277.772 131.28C262.237 117.932 238.852 119.71 225.54 135.251L222.809 138.438L233.927 145.721C236.195 147.207 235.862 150.766 233.361 151.778L184.446 171.554C182.419 172.373 180.213 170.928 180.037 168.665L175.796 114.068C175.579 111.276 178.502 109.414 180.77 110.899L192 118.256Z"
              fill="white"/>
            <path
              d="M192 118.256L197.834 111.445C224.258 80.5975 270.677 77.0685 301.514 103.564C332.351 130.059 335.927 176.545 309.502 207.393C285.841 235.016 246.182 240.72 215.995 222.617L203.249 214.973C201.64 214.009 201.115 211.922 202.077 210.311L217.314 184.803C218.276 183.193 220.36 182.669 221.969 183.634L234.715 191.278C249.906 200.387 269.894 197.482 281.797 183.588C295.109 168.047 293.307 144.628 277.772 131.28C262.237 117.932 238.852 119.71 225.54 135.251L222.809 138.438L233.927 145.721C236.195 147.207 235.862 150.766 233.361 151.778L184.446 171.554C182.419 172.373 180.213 170.928 180.037 168.665L175.796 114.068C175.579 111.276 178.502 109.414 180.77 110.899L192 118.256Z"
              fill="url(#paint1_linear_7505_33127)"/>
          </g>
          <defs>
            <filter id="filter0_dd_7505_33127" x="0.240001" y="0.951857" width="332.72" height="249.12"
                    filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
              <feFlood floodOpacity="0" result="BackgroundImageFix"/>
              <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                             result="hardAlpha"/>
              <feOffset dy="2.56"/>
              <feGaussianBlur stdDeviation="2.88"/>
              <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.13 0"/>
              <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_7505_33127"/>
              <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                             result="hardAlpha"/>
              <feOffset dy="0.48"/>
              <feGaussianBlur stdDeviation="0.72"/>
              <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"/>
              <feBlend mode="normal" in2="effect1_dropShadow_7505_33127" result="effect2_dropShadow_7505_33127"/>
              <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_7505_33127" result="shape"/>
            </filter>
            <linearGradient id="paint0_linear_7505_33127" x1="147.485" y1="4.15186" x2="147.485" y2="241.571"
                            gradientUnits="userSpaceOnUse">
              <stop stopColor="#407EC9"/>
              <stop offset="1" stopColor="#31629C"/>
            </linearGradient>
            <linearGradient id="paint1_linear_7505_33127" x1="251.492" y1="85.7883" x2="251.492" y2="233.047"
                            gradientUnits="userSpaceOnUse">
              <stop stopColor="#407EC9"/>
              <stop offset="1" stopColor="#31629C"/>
            </linearGradient>
            <clipPath id="clip0_7505_33127">
              <rect width="321.2" height="237.6" fill="white" transform="translate(6 4.15186)"/>
            </clipPath>
          </defs>
        </svg>
      </div>
      <div id="progress">
        <div className="progress-title">Installing...</div>
        <div className="progress-line">
          <span/>
        </div>
        <span className="progress-percent"/>
      </div>
    </div>
  )
}

export default Preload
