import axios from 'axios';
import {TOKEN} from "../constants";
import { Cookies } from "react-cookie";
import mainJson from '../../package.json';
const cookies = new Cookies();

const checkResponse = (response: any) => response.data;
const catchError = (error: any) => error;

export const wrapper = (method: "post" | "get" | "put" | "delete", url: string, data?: any) => {
  axios.defaults.headers.common['version'] = mainJson.apiVersion;
  return axios.request({method, url, data}).then(checkResponse).catch(catchError);
}

export const wrapperWithToken = (method: "post" | "get" | "put" | "delete", url: string, data?: any) => {
  axios.defaults.headers.common['version'] = mainJson.apiVersion;
  axios.defaults.headers.common['token'] = cookies.get('token') || TOKEN;
  return axios.request({method, url, data}).then(checkResponse).catch(catchError);
}
