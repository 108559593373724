import React from "react";
import PageTop from "../../components/PageTop/PageTop";
import link from '../../assets/img/link.svg';
import {Button, Text} from "@clout-team/web-components";
import './NotFound.scss';

const NotFound = () => {
  return (
    <div className={'not-found'}>
      <PageTop/>
      <div className={'not-found--content'}>
        <div className={'not-found--link'}>
          <img src={link} alt={'link'}/>
        </div>
        <div className={'not-found--title'}>
          Упс! <br/>Эта страница недоступна.
        </div>
        <Text>У вас нет доступа или эта ссылка больше не существует.</Text>
        <div className={'on-main'}>
          <Button onClick={() => document.location.href = document.location.origin}>На главную</Button>
        </div>
      </div>
    </div>
  )
}

export default NotFound;
