import React, {useEffect, useRef, useState} from "react";
import {AppDocument, Button, Checkbox, Dialog, FileDropWrap, Header, Input, Layout, Text} from "@clout-team/web-components";
import {addFiles, profileGet, registrationUser, uploadFile} from "../../api";
import {isValidEmail} from "../../helpers";
import './UserDialog.scss';
import {IUser} from "../../interface";
import {getUrlParamsByKey} from "@clout-team/helpers";
import {PROJECT_PARAM} from "../../constants";
import { Cookies } from "react-cookie";
const cookies = new Cookies();

const UserDialog = (props: {
  onClose: () => void
  setSuccessDialog: (state: boolean) => void,
  setUser: (user: IUser) => void
  title?: string
}) => {
  const {onClose, setSuccessDialog, setUser, title = 'Заполните данные'} = props;
  const input: React.RefObject<HTMLInputElement> = useRef(null);
  const [files, setFiles] = useState<any>([]);
  const [userName, setUserName] = useState<string>('');
  const [userSurname, setUserSurname] = useState<string>('');
  const [userEmail, setUserEmail] = useState<string>('');
  const [userPhone, setUserPhone] = useState<string>('');
  const [disableSend, setDisableSend] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const [isCheckedMail, setIsCheckedMail] = useState<boolean>(true);
  const [isCheckedPhone, setIsCheckedPhone] = useState<boolean>(true);

  useEffect(() => {
    const token = cookies.get('token')
    if (token) {
      profileGet().then(res => {
        if (res.response && res.response.id) {
          localStorage.setItem('userId', String(res.response.id))
        }
      })
    }
  }, [])

  const nextHandle = async () => {
    setLoading(true);
    const project = getUrlParamsByKey(document.location.href, PROJECT_PARAM);
    let params: any = {
      hash: project,
      name: userName,
      surname: userSurname,
      email: userEmail,
      description: ``,
      phone: userPhone,
      sendEmail: isCheckedMail ? 'true' : '',
      sendPhone: isCheckedPhone ? 'true' : ''
    }

    const userId = localStorage.getItem('userId');
    if (userId) params.responsibleId = parseInt(userId);

    const res: any = await registrationUser(params);
    if (res && res.response && res.response.hash) {
      localStorage.setItem('hash', res.response.hash);
      window.dispatchEvent(new Event('storage'));
      if (files.length > 0) addFiles(res.response.hash, files);
      setUser({
        name: userName,
        surname: userSurname,
        email: userEmail
      });
      setSuccessDialog(true);
      onClose();
    } else {
      onClose();
      alert('Проект не найден')
    }
  }

  useEffect(() => {
    if (userName.length === 0 || userSurname.length === 0 || !isValidEmail(userEmail)) {
      setDisableSend(true)
    } else {
      setDisableSend(false)
    }
  }, [userName, userSurname, userEmail])

  const fileClickHandle = () => {
    input.current?.click();
  }

  const dropOnChange = (e: any) => {
    if (e.length) {
      setFiles((state: any) => {
        return [...state, ...e]
      })
    } else {
      setFiles((state: any) => {
        return [...state, ...(e.target.files)]
      })
    }
  }

  const fileInputOnChange = async (e: any) => {
    if (e.target.files.length > 0) {
      const files: any = [];
      for (let i = 0, numFiles = e.target.files.length; i < numFiles; i++) {
        files.push(e.target.files[i])
      }
      setFiles((state: any) => {
        return [...state, ...files]
      })

      if (files.length > 0) {
        const info: any = await uploadFile(files);
        if (info.response) {
          const userInfo = info.response.parsed_user
          setUser({
            name: userInfo.name,
            surname: userInfo.surname,
            email: userInfo.email
          });
          setUserName(userInfo.name)
          setUserSurname(userInfo.surname)
          setUserEmail(userInfo.email)
          setUserPhone(`+${userInfo.phone}`)
        }
      }
    }
  }

  const onChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUserName(e.target.value)
  }

  const onChangeSurname = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUserSurname(e.target.value)
  }

  const onChangeEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUserEmail(e.target.value)
  }

  const onChangePhone = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUserPhone(e.target.value)
  }

  const onChangeCheckBoxMail = () => {
    setIsCheckedMail(!isCheckedMail);
  }

  const onChangeCheckBoxPhone = () => {
    setIsCheckedPhone(!isCheckedPhone);
  }

  return (
    <Dialog
      isScrollContent
      closeOnClickOutside={false}
      className={'add-user-dialog'}
      dialogTitle={{
        title: title
      }}
      dialogButtons={{
        right: [
          <Button key={'btn-5'} size={'lg'} inverted={true} color={'white'}
                  onClick={() => onClose()}>
            Отмена
          </Button>,
          <Button disabled={disableSend} key={'btn-6'} size={'lg'} onClick={nextHandle} loading={loading}>
            Далее
          </Button>
        ]
      }}
      onClose={onClose}
    >
      <Layout gap={'lg'} vertical={true}>
        <Layout gap={'lg'}>
          <div className={'text-input'} key={'i-1'}>
            <Header size={'sm'} left={<Text type={'secondary'} size={'footnote'}>Имя</Text>} noPadding={true}/>
            <Input capitalizeValue={false} value={userName} focus onDismissClick={() => setUserName('')} onChange={onChangeName} placeholder={'Введите имя'}/>
          </div>
          <div className={'text-input'} key={'i-2'}>
            <Header size={'sm'} left={<Text type={'secondary'} size={'footnote'}>Фамилия</Text>} noPadding={true}/>
            <Input capitalizeValue={false} value={userSurname} onDismissClick={() => setUserSurname('')} onChange={onChangeSurname} placeholder={'Введите фамилию'}/>
          </div>
        </Layout>
        <div className={'text-input'} key={'i-3'}>
          <Header size={'sm'} left={<Text type={'secondary'} size={'footnote'}>Ваша почта</Text>} noPadding={true}/>
          <Input capitalizeValue={false} value={userEmail} onDismissClick={() => setUserEmail('')} onChange={onChangeEmail} placeholder={'Введите вашу почту'}/>
        </div>
        <div className={'text-input'} key={'i-4'}>
          <Header size={'sm'} left={<Text type={'secondary'} size={'footnote'}>Ваш телефон</Text>} noPadding={true}/>
          <Input capitalizeValue={false} value={userPhone} onDismissClick={() => setUserPhone('')} onChange={onChangePhone} placeholder={'Введите номер в формате +79991112233'}/>
        </div>
        <div className={'text-input'} key={'i-5'}>
          <Header size={'sm'} left={<Text type={'secondary'} size={'footnote'}>Приложите резюме</Text>}
                  noPadding={true}/>
          <Layout gap={'sm'} vertical={true}>
            <div className={'drop-file-item'}>
              <FileDropWrap onChange={dropOnChange}>
                <Text>Перетащите сюда файлы</Text>
                <Button onClick={fileClickHandle}>Выбрать</Button>
                <input type={"file"} ref={input} onChange={fileInputOnChange} multiple={true}/>
              </FileDropWrap>
            </div>
            {files.length > 0 && (
              files.map((file: any) => {
                const item = {
                  filename: file.name,
                  name: file.name,
                  type: file.type,
                  size: file.size
                }
                return (
                  <AppDocument key={file.name} item={item}/>
                );
              })
            )}
          </Layout>

        </div>
        <Layout gap={'sm'} vertical>
        <Layout gap={'sm'} isAlignCenter={true}>
          <Checkbox
            size="lg"
            id={'mail'}
            onChange={onChangeCheckBoxMail}
            isChecked={isCheckedMail}
            className="chat-main__message-checkbox"
          />
          <Text>Отправить на почту</Text>
        </Layout>
        <Layout gap={'sm'} isAlignCenter={true}>
          <Checkbox
            size="lg"
            id={'phone'}
            onChange={onChangeCheckBoxPhone}
            isChecked={isCheckedPhone}
            className="chat-main__message-checkbox"
          />
          <Text>Отправить SMS</Text>
        </Layout>
        </Layout>
      </Layout>
    </Dialog>
  )
}

export default UserDialog;

