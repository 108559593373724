import {wrapper, wrapperWithToken} from "../helpers/wrapper";
import {URLS} from "../constants/urls";
import {TOKEN} from "../constants";
import mainJson from '../../package.json';

export const profileGet = () => {
  return wrapperWithToken('get', URLS.PROFILE_GET, {})
}

export const registrationUser = (props: {
  hash: string
  name: string
  surname: string
  email: string
  phone: string
  description: string
  sendEmail: string
  sendPhone: string
  responsibleId?: number
}) => {
  const {hash, name, surname, email = '', phone = '', description = '', sendEmail = 'true', sendPhone = 'true', responsibleId} = props;
  let params: any = {
    surname,
    name,
    hash,
    email,
    description,
    phone,
    send_sms: sendPhone,
    send_email: sendEmail
  }
  if (responsibleId) params.responsible_id = responsibleId;
  return wrapper('post', URLS.CREATE_WORK_USER, params)
}

export const createWork = (projectId: string, isPublic: boolean, name: string, surname: string, email: string) => {
  return wrapperWithToken('post', URLS.WORK_TEST_CREATE, {
    project_id: projectId,
    is_public: isPublic,
    name,
    surname,
    email
  })
}

export const viewWork = async (hash: string) => {
  return wrapper('get', `${URLS.VIEW_WORK}?hash=${hash}`, {})
}

export const addComment = async (hash: string, content: string, files: any[] = []) => {
  const str = new FormData();
  if (files.length > 0) {
    for (const v in files) {
      str.append('files[]', files[v]);
    }
  }
  str.append('hash', hash);
  str.append('content', content);
  return wrapper('post', URLS.ADD_COMMENT, str)
}

export const addFiles = (hash: string, filesArr: any) => {
  return new Promise(function (resolve) {
    const str = new FormData();
    if (filesArr.length > 0) {
      for (const v in filesArr) {
        str.append('files[]', filesArr[v]);
      }
    }
    str.append('hash', hash);
    const xhr = new XMLHttpRequest();
    xhr.open("POST", URLS.ADD_FILES, true);
    xhr.setRequestHeader('version', mainJson.apiVersion);
    xhr.onreadystatechange = function (e: any) {
      if (e.target.readyState === 4 && e.target.status === 200)
        resolve(JSON.parse(e.target.response));
    };
    xhr.send(str);
  });
}

export const uploadFile = (filesArr: any) => {
  return new Promise(function (resolve) {
    const token = TOKEN;
    const str = new FormData();
    if (filesArr.length > 0) {
      for (const v in filesArr) {
        str.append('files[]', filesArr[v]);
      }
    }
    str.append('token', token);
    const xhr = new XMLHttpRequest();
    xhr.open("POST", URLS.UPLOAD_FILE, true);
    xhr.setRequestHeader('version', mainJson.apiVersion);
    xhr.onreadystatechange = function (e: any) {
      if (e.target.readyState === 4 && e.target.status === 200)
        resolve(JSON.parse(e.target.response));
    };
    xhr.send(str);
  });
}
