export const mediaPrev = {
  created_at: 1648743316,
  duration: 122.08,
  filename: "Как работать с Git.mp4",
  height: 1080,
  id: 90285,
  info: {width: 1920, height: 1080, duration: 122.08},
  is_original_image: false,
  link: "https://files.lenzaos.com/other/git.png",
  mime_type: "video/mp4",
  placeholder: "https://files.lenzaos.com/other/git.png",
  preview: "",
  size: 483941255,
  subject_id: 4051,
  type: "mp4",
  width: 1920,
};

export const media = {
  created_at: 1648743316,
  duration: 122.08,
  filename: "Как работать с Git.mp4",
  height: 1080,
  id: 90285,
  info: {width: 1920, height: 1080, duration: 122.08},
  is_original_image: false,
  link: "https://files.lenzaos.com/other/git.mp4",
  mime_type: "video/mp4",
  placeholder: "https://files.lenzaos.com/other/git.png",
  preview: "",
  size: 483941255,
  subject_id: 4051,
  type: "mp4",
  width: 1920,
  autoplay: true
};

export const cloutTestFile = {
  filename: 'clout-web-test.zip',
  file_extension: 'zip',
  name: 'clout-web-test.zip',
  type: 'zip',
  size: 192144,
  link: 'https://files.lenzaos.com/other/clout-web-test.zip',
}

export const cloutAsistentFile = {
  filename: 'invoice.pdf',
  file_extension: 'application/pdf',
  name: 'invoice.pdf',
  type: 'application/pdf',
  size: 192144,
  link: 'https://files.lenzaos.com/other/invoice.pdf',
}
