import React, {useEffect} from "react";
import {Switch, Route} from "react-router-dom";
import Home from "./pages/Home/Home";
import Main from "./pages/Main/Main";
import './style.scss';
import NotFound from "./pages/NotFound/NotFound";

const App = () => {
  return (
    <div className="App">
      <Switch>
        <Route exact path={'/'} render={() => <Home/>}/>
        <Route exact path={`/:hash`} render={() => <Main/>}/>
        <Route path={`*`} render={() => <NotFound/>}/>
      </Switch>
    </div>
  )
}

export default App;
