import React, {useEffect, useState} from "react";
import {getDifferentTimestamp} from "../../helpers";

let timeOut: ReturnType<typeof setTimeout>;
const Timer = (props: {
  start: boolean
  startTime?: number // timestamp
}) => {
  const {start, startTime} = props;
  const [time, setTime] = useState<string>('0 час 0 мин 00 сек')

  useEffect(() => {
    if (start) {
      let hours = 0;
      let mins = 0;
      let seconds = 0;

      if (startTime && startTime > 0) {
        const date = getDifferentTimestamp(startTime, Date.now());
        hours = date.h
        mins = date.m
        seconds = date.s
      }

      const startTimer = () => {
        let h = `${hours} час`, m = ` ${mins} мин`, s = ` ${seconds} сек`;
        timeOut = setTimeout(function () {
          seconds++;
          if (seconds > 59) {
            seconds = 0;
            mins++;
            if (mins > 59) {
              mins = 0;
              hours++;
              h = `${hours} час`
            }

            m = ` ${mins} мин`
          }
          if (seconds < 10) {
            s = ` 0${seconds} сек`
          } else {
            s = ` ${seconds} сек`
          }

          setTime(h + m + s);
          startTimer();
        }, 1000);
      }

      startTimer();
    } else clearTimeout(timeOut);

    return () => clearTimeout(timeOut)
  }, [start, startTime])

  return time;
}

export default Timer;
