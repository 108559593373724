import React from "react";
import './PageTop.scss';
import {LogoClout} from "@clout-team/icons/react";

const PageTop = () => {

  return (
    <div className={'page-top'}>
      <div className={'test-logo'}>
        <LogoClout/>
        Clout.test
      </div>
    </div>
  )
}

export default PageTop;
